import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    public http: HttpClient,
    public router: Router,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) { }


  post<T>(path: string, data: any, headers?: HttpHeaders) { return this.http.post<T>(path, data, {headers}); }

  get<T>(path: string, data: any, cache: boolean) { return this.http.get<T>(path, {params: data}); }

  delete(path: string, data: any = null) { return this.http.delete(path, {params: data}); }


}
