import {Injectable} from '@angular/core';
import {ApiService} from "../common/services/api.service";

@Injectable({
  providedIn: 'root',
})
export class ModelLoaderService extends ApiService {

  load(type: string, params = null) {
    return this.get<any>('load/models/' + type, params, true);
  }
}
