import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';
import {FileUploadModule} from "primeng/fileupload";
import {CdnImageComponent} from "./cdn-image/cdn-image.component";
import {NgIf, NgStyle} from "@angular/common";

@Component({
  selector: 'app-image-upload',
  standalone: true,
  template: `
      <input style="display: none" value="" type="file" #fileInput (change)="upload($event)" [multiple]="false">
      <div class="flex items-center">
          <div class="relative">
              <div class="relative not-prose border border-gray-200 rounded p-2" *ngIf="uploadedFiles.length>0">
                  <app-cdn-image [src]="uploadedFiles[0]" width="150" height="0"></app-cdn-image>
                  <a class="absolute right-0 top-0 bg-red-500 p-3 no-underline" *ngIf="uploadedFiles.length>0"
                     (click)="clearImage()">X </a>
              </div>

              <div class="placeholder bg-green-50 flex justify-center items-center"
                   [ngStyle]="{width:'150px', height:'150px'}"
                   (click)="fileInput.click()"
                   *ngIf="uploadedFiles.length==0">
                  <ng-container *ngIf="uploadedFiles.length===0">
                      <i class="fa fa-plus"></i>
                      <span class="label">{{label}}</span>
                  </ng-container>

              </div>
              <div class="progress-bar" *ngIf="progress>0">
                  <div class="bar" [ngStyle]="{width: progress+'%'}"></div>
                  <span>Uploading  {{progress}}%</span>
              </div>
          </div>
      </div>
      <small class="text-danger" *ngIf="invalidFile">Only jpg,jpeg,png images</small>
  `,
  imports: [
    FileUploadModule,
    CdnImageComponent,
    NgStyle,
    NgIf
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true
    }
  ]
})
export class ImageUploadComponent implements ControlValueAccessor {
  @Input() public url;
  @Input() public multiple = false;
  @Input() public withCredentials = true;
  @Input() public label = 'Upload';
  @Input() public dimensions;
  @ViewChild('fileInput') public fileInput;
  public value: any;
  public uploading = false;
  public directory: string;
  public progress = 0;
  public invalidFile = false;
  public files = [];
  public uploadedFiles = [];
  public propagateChange = (_) => {
  };

  constructor(public http: HttpClient, public cd: ChangeDetectorRef) {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.uploadedFiles = this.multiple ? [] : [obj];
    } else {
      this.uploadedFiles = [];
    }
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {

  }

  public upload($event) {
    this.files = [...this.files, ...$event.target.files];
    this.invalidFile = false;
    const formData = new FormData();
    this.files.forEach((f) => {
      formData.append(this.multiple ? 'file[]' : 'file', f);
    });
    //
    this.http.post(this.url, formData, {
      reportProgress: true,
      observe: 'events',
      withCredentials: this.withCredentials
    }).subscribe({
      next: (event: HttpEvent<any>) => {
        this.files = $event.files;
        switch (event.type) {
          case HttpEventType.Response:
            this.uploading = false;
            this.progress = 0;
            console.log(event.body);
            if (this.multiple) {
              this.uploadedFiles = [...this.uploadedFiles, ...event.body];
            } else {
              this.uploadedFiles = [...event.body];
            }
            console.log(this.uploadedFiles)
            this.resetFileSelector();
            const res = this.multiple ? this.uploadedFiles : this.uploadedFiles[0];

            if (this.multiple) {
              this.propagateChange(res);
            } else {
              this.propagateChange(res);
            }
            break;
          case HttpEventType.UploadProgress:
            if (event.loaded) {
              this.progress = Math.round((event.loaded / event.total) * 100);
            }
            break;
        }
        this.cd.detectChanges();
        this.cd.markForCheck();
      },
      error: (error) => {
        this.uploading = false;
        if (error.status === 422) {
          this.resetFileSelector();
          this.invalidFile = true;
          this.cd.detectChanges();
        }
      }
    })

  }

  resetFileSelector() {
    this.files = [];
    this.progress = 0;
    this.fileInput.nativeElement.value = '';
  }

  clearImage() {
    this.uploadedFiles = [];
    this.propagateChange(null);
  }

  onUpload($event: any) {

  }
}
