import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading-btn',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button [type]="type" class="center-all btn disabled:bg-grey-200 bg-red-400 text-white" [disabled]="loading">
      <i [ngClass]="{'w-5 mr-2':loading,'w-0':!loading}" class="pi duration-75 transition-all w-5 overflow-hidden pi-spinner animate-spin"></i> {{label}}
    </button>
  `,
  styles: [`

  `],
})
export class LoadingBtnComponent {
  @Input() type = 'submit';
  @Input() loading = false;
  @Input() label!: string;

}
