import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-cdn-image',
  templateUrl: './cdn-image.component.html',
  styleUrls: ['./cdn-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CdnImageComponent implements AfterViewInit, OnChanges {
  @Input() src: string | undefined;
  @Input() width = 100;
  @Input() height = 100;
  @Input() fallback: string;

  public finalUrl: string | undefined;

  constructor(public cd: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.process();
    });
  }

  process() {
    if (this.src) {
      if (this.src.indexOf('http') > -1) {
        this.finalUrl = this.src;
      } else {
        this.finalUrl = 'https://ik.imagekit.io/aqcg4zaiu7s/' + this.src.replace('public/', '') + '?tr=fo-auto,lo-true,q-100,w-' + this.width + ',h-' + this.height;
      }
    } else {
      this.finalUrl = this.fallback
    }
    this.cd.detectChanges();

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.process();
  }
}
